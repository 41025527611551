$primary: #1890ff;
$transition: all cubic-bezier(0.215, 0.61, 0.355, 1);

body {
  font-family: 'Nunito', sans-serif;
}

.site-layout .site-layout-background {

}

.logo-sm {
  display: block;
  margin: auto;
  width: 60px;
  padding: 20px;
}

.ant-layout-sider {
  //box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}

.site-layout-card {
  border: solid 1px #f0f0f0;
  //box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  padding: 24px;
  margin-bottom: 20px;
  border-radius: 12px;
  position: relative;
  &.disabled {
    opacity: 0.4;
  }
}

.loading {

}

.translation-row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .anticon {
    position: absolute;
    margin-left: -20px;
    top: 8px;
    color: #44ad1b;
  }
}

.header {
  display: flex;
  justify-content: center;
  padding: 0;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);

  .user-nav {
    .ant-dropdown-trigger {
      margin: 0!important;
    }

    position: absolute;
    right: 0;
    top: 0;
    height: 64px;
    color: white;
    font-weight: 600;
    margin-right: 20px;

    .nav-avatar {
      cursor: pointer;
      color: #262626;
      &:hover {
        border: solid 1px $primary;
        color: $primary;
      }
    }

    .ant-dropdown-open {
      .nav-avatar {
        border: solid 1px $primary;
        color: $primary;
      }
    }
  }
}

h1 {
  font-weight: bold;
}

.custom-h1 {
  font-weight: 500;
  font-size: 30px;
  margin: 0;
}

.recipe-img {
  max-height: 40px;
  border-radius: 6px;
}

.delete-comment {
  color: red!important;
}

.ant-list-item-meta-description {
  color: rgba(0,0,0,0.85);
}

.add-translation-btn, .add-ingredient-btn {
  margin-right: auto;
}

.ant-layout-sider-collapsed {
  .site-select {
    display: none!important;
  }
}

.action-btn {
  font-size: 12px;
  margin-top: 10px;
  border-radius: 12px;
  padding: 2px 12px;
}

.create-comment {
  margin-top: 10px;

  textarea {
    margin-bottom: 10px;
  }
  button {
    margin-right: 10px;
  }
}
.task-today {
  //background-color: #e6f7ff;
}

.ingredient-icon-picker {
  width: 1024px!important;
  text-align: center;
  .ant-list-item {
    cursor: pointer;
    border-radius: 6px;
    padding: 12px!important;
    border: solid 1px #eee!important;
    &:hover {
      background: rgba(128, 128, 128, 0.10);
    }
  }
}

.ql-toolbar,  .ql-editor {
  font-family: 'Nunito', sans-serif!important;
  font-size: 14px!important;
}

.ql-snow {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

// Recharts
.recharts-wrapper {
  cursor: pointer !important;

  text {
    fill: #828282;
  }

  .recharts-cartesian-axis-tick-line, .recharts-cartesian-grid-vertical, .recharts-cartesian-axis-line {
    display: none !important;
  }

  .recharts-cartesian-grid-horizontal {
    line {
      stroke: rgba(255, 255, 255, 0.3);
    }
  }

  .recharts-surface {
    border-radius: 4px;
  }

  .recharts-tooltip-wrapper {
    outline: none!important;
  }

  .recharts-default-tooltip {
    background: rgba(255, 255, 255, 0.5);
    outline: none!important;
    border: solid 1px rgba(255, 255, 255, 0.3)!important;
    border-radius: 6px;
    opacity: 0.95;
    font-weight: 600 !important;
  }
}

.spinner-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Additional styling to prevent overflow */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.darkmode {
  .recharts-default-tooltip {
    background: rgba(0, 0, 0, 0.3) !important;
  }

  .site-layout-card {
    //border: solid 1px #313131;
    border: transparent;
  }
}

.invoices {
  .ant-list-item-meta-description {
    font-size: 12px !important;
  }
}
